import React from 'react';
import { Box, Typography, Grid2 } from '@mui/material';

function Praxis() {
  return (
    <div style={{ padding: '50px' }}>
      <Typography variant="h4" align="center">Standorte</Typography>
      <Typography variant="body1" align="center" style={{ marginBottom: '20px' }}>
        Wir üben unsere Heilpraktiker Leistungen an diesen Standorten für Sie aus.
      </Typography>

      <Grid2 container spacing={4} justifyContent="center">
        {/* Location 1 */}
        <Grid2 item xs={12} sm={4}>
          <Box
            sx={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              bgcolor: '#fefedf',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)', // Shadow effect for card style
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d607.9803191948037!2d13.260434928600699!3d52.44434081569137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85a3b4ad5243d%3A0x7d0994d58d7bc838!2sRiemeisterstra%C3%9Fe%2039A%2C%2014169%20Berlin!5e0!3m2!1sde!2sde!4v1730020674847!5m2!1sde!2sde"
              width="200"
              height="150"
              style={{ borderRadius: '8px', marginBottom: '10px', border: '0' }}
              allowFullScreen=""
              loading="lazy"
              title="Praxis 1 Location Map"
            ></iframe>
            <Typography variant="h6">Praxis 1</Typography>
            <Typography variant="body2">Kosmetikstudio Lotus-Nail-Spa, <br /> Riemeisterstraße 39 a <br /> 14169 Berlin
            </Typography>
          </Box>
        </Grid2>

        {/* Location 2 */}
        {/* <Grid2 item xs={12} sm={4}>
          <Box
            sx={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              bgcolor: '#fefedf',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)', // Shadow effect for card style
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.050395401578!2d13.275318912830132!3d52.478223239204404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a850a5862f9403%3A0x195d2e788caa4c8c!2sHagenstra%C3%9Fe%2050%2C%2014193%20Berlin!5e0!3m2!1sde!2sde!4v1730020773453!5m2!1sde!2sde"
              width="200"
              height="150"
              style={{ borderRadius: '8px', marginBottom: '10px', border: '0' }}
              allowFullScreen=""
              loading="lazy"
              title="Praxis 2 Location Map"
            ></iframe>
            <Typography variant="h6">Praxis 2</Typography>
            <Typography variant="body2">Hagenstraße 50 <br /> 14193 Berlin</Typography>
          </Box>
        </Grid2> */}

      </Grid2>
    </div>
  );
}

export default Praxis;
